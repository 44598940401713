<template>
  <div class="login_container">
    <div class="content">
      <div class="login">
        <h3 class="title">{{ translateTitle("登录") }}</h3>
        <div class="login-form">
          <el-form
            ref="loginForm"
            :rules="formRules"
            :model="loginData"
            label-position="top"
            @submit.native.prevent
          >
            <el-form-item
              prop="station"
              class="station-select"
              v-if="environment == 'test' || environment == undefined"
            >
              <el-select
                v-model="station"
                @change="onStationChange"
                placeholder="请选择测试站点"
              >
                <el-option
                  v-for="item in stationsOptions"
                  :key="item.app_id"
                  :label="item.name + '-' + item.channel_id"
                  :value="item.app_id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="account" class="account">
              <el-input
                @focus="onFocusAccount($event)"
                v-model.trim="loginData.account"
                :placeholder="translateTitle('邮箱 / 手机号')"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item prop="pwd" class="pwd">
              <el-input
                :key="passwordType"
                :type="passwordType"
                v-model.trim="loginData.pwd"
                autocomplete="off"
                :placeholder="translateTitle('密码')"
                @keyup.enter.native="onLoginShowVcode"
                maxlength="20"
              >
                <img
                  class="close"
                  v-if="passwordType === 'password'"
                  slot="suffix"
                  @click="handlePassword"
                  src="../../assets/img/login/eye-off.png"
                />
                <img
                  v-else
                  class="open"
                  slot="suffix"
                  @click="handlePassword"
                  src="../../assets/img/login/eye-open.png"
                />
              </el-input>
            </el-form-item>
            <el-button
              class="login-btn theme-btn"
              type="primary"
              :loading="loginLoading"
              @click="onLoginShowVcode"
              >{{ translateTitle("登录") }}</el-button
            >
          </el-form>
        </div>
        <div class="register-forget">
          <span
            >{{ translateTitle("没有账号？") }}{{ translateTitle("去") }}
            <router-link
              class="forget go-resiger"
              :to="routeSplicing(language, 'register')"
              >{{ translateTitle("注册") }}</router-link
            >
          </span>
          <router-link
            class="forget"
            :to="routeSplicing(language, 'forgetPwd')"
            >{{ translateTitle("忘记密码") }}</router-link
          >
        </div>
      </div>
      <div v-if="isShowBanner" class="bottom">
        <!-- <img src="../../assets/img/login-app.png" alt="" /> -->
        <div class="banner-content">
          <div class="left-banner">
            <qrcode-vue
              v-if="isB9"
              class="qr_code_canvas"
              value="http://download.b9.com/"
              :size="63"
              level="H"
            ></qrcode-vue>
            <img v-else src="../../assets/img/appDownLoad.png" alt="" />
            <div class="left-text">
              <div class="banner-top">
                <div class="banner-title">
                  {{ bannerTitle }}{{ translateTitle("全球站App") }}
                </div>
                <div class="banner-sub-title">{{ translateTitle(slogan) }}</div>
              </div>
              <div class="banner-bottom">
                {{ translateTitle("扫码二维码下载App") }}
              </div>
            </div>
          </div>
          <div class="right-banner">
            <img src="../../assets/img/login/banner.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Vcode
      @close="onCloseVcode"
      :imgs="imgs"
      :show="isShow"
      :successText="translateTitle('验证通过')"
      :failText="translateTitle('验证失败，请重试')"
      :sliderText="translateTitle('拖动滑块完成拼图')"
      @success="success"
    />
    <Geetest :isGeet="isShowGeet" :isRegister="false" @geetParam="success" />
    <!-- <div class="copyright">
      <span>Copyright &copy; 2021, Topcredit All Rights Reserved</span>
    </div> -->
  </div>
</template>
<script>
import md5 from "js-md5";
import Cookies from "js-cookie";
// const { VUE_APP_ID, VUE_APP_APP } = process.env;
import { mapActions, mapGetters } from "vuex";
import {
  VUE_UNION_STATION,
  VUE_APP_ID,
  VUE_APP_ENV,
  apiGetChannelList,
} from "@/assets/js/stationSetting";
import Vcode from "vue-puzzle-vcode"; //页面内引用插件
// import * as Account from "@model/user/account";
import {
  universalLogin,
  universalAccountV2Login,
  universalAccountV2LoginGeet,
} from "@/model/login/index";

import { showMessage } from "@/components/dialog/message";
import img1 from "../../assets/img/rePhoto/1.png";
import img2 from "../../assets/img/rePhoto/2.png";
import commonMixin from "@/components/common/commonMixin.vue";
// import { getCoinList } from "@/model/assets/index";
import { translateTitle } from "@/assets/js/i18n";
import routeSplicing from "@/assets/js/routeSplicing";
import Geetest from "@/components/Geetest.vue";
import QrcodeVue from "qrcode.vue";
export default {
  mixins: [commonMixin],

  data() {
    return {
      voucherInterval: null,
      loginLoading: false, // 登录loading
      isShow: false, // 用来控制显示是否显示图片滑动验证框
      photoHua: 0, //图片滑动验证的错误次数
      img1,
      img2,
      imgs: [],
      slogan: "让金融更高效 让财富更自由",
      appDownLoadImg: "",
      station: "",
      stationsOptions: [],
      sucessData: {},
      loginStatus: "",
      environment: VUE_APP_ENV,
      isB9: false,
      isShowBanner: false,
      loginMsg: "",
      loginData: {
        account: "",
      },
      passwordType: "password",
      bannerTitle: "汇信",
      // 登录数据
      formRules: {
        account: [
          {
            required: true,
            message: this.translateTitle("邮箱/手机号/用户名不能为空"),
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: this.translateTitle("密码不能为空"),
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: this.translateTitle("密码长度为 6-20 位字符"),
            trigger: "blur",
          },
        ],
      },
      isShowGeet: false,
      geetParam: null,
    };
  },
  components: { Vcode, Geetest, QrcodeVue },
  created() {
    if (this.environment == "test") {
      this.getChannelList();
    }

    this.imgs.push(img2);
    this.isShowBanner = false;
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  mounted() {
    // 获取url参数
    this.getParams();
    // this.initLoginEnter();
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  methods: {
    routeSplicing,
    translateTitle,
    ...mapActions({
      changeEnvironment: "user/changeEnvironment",
    }),
    onStationChange(value) {
      this.stationsOptions.forEach(ele => {
        if (ele.app_id == value) {
          localStorage.removeItem("settingList");
          localStorage.setItem("web-id", value);
          localStorage.setItem("stationValue", JSON.stringify(ele));
          let tempValue = ele.name + "-" + ele.channel_id;
          this.changeEnvironment(tempValue);
        }
      });
      window.location.reload();
    },
    getChannelList() {
      const { status, data } = apiGetChannelList();
      if (status == 200) {
        this.stationsOptions = data;
        this.stationsOptions.forEach(ele => {
          let tempVal = JSON.parse(localStorage.getItem("stationValue"));
          if (tempVal) {
            if (ele.app_id == tempVal.app_id) {
              this.station = ele.app_id;
              let tempValue = ele.name + "-" + ele.channel_id;
              this.changeEnvironment(tempValue);
            }
          } else {
            if (ele.app_id == VUE_APP_ID) {
              localStorage.setItem("stationValue", JSON.stringify(ele));
              this.station = ele.app_id;
              let tempValue = ele.name + "-" + ele.channel_id;
              this.changeEnvironment(tempValue);
            }
          }
        });
      }
      // });
    },
    success(geetParam) {
      this.geetParam = geetParam;
      this.onLogin();
      // this.handLoginSuccess(this.sucessData, this.loginStatus, this.loginMsg);
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },
    onCloseVcode() {
      this.isShow = false;
    },
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    getParams() {
      if (this.$route.params && this.$route.params.username) {
        let { username } = this.$route.params;
        this.loginData.account = username;
      }
    },
    handlePassword() {
      this.passwordType === "password"
        ? (this.passwordType = "")
        : (this.passwordType = "password");
      // this.$nextTick(() => {
      //   this.$refs.password.focus();
      // });
    },
    onFocusAccount(e) {
      // console.log(e.currentTarget.previousElementSibling);
    },
    initLoginEnter() {
      document.onkeyup = e => {
        let keycode = document.all ? event.keyCode : e.which;
        // 在登录界面 且 按得键是回车键enter
        if (
          (this.$route.path == "/login" ||
            this.$route.path == this.routeSplicing(this.language, "login")) &&
          keycode === 13
        ) {
          this.onLoginShowVcode();
          // this.onLogin();
        }
      };
    },
    onLoginShowVcode() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          // this.isShow = true;
          this.isShowGeet = !this.isShowGeet;
        }
      });
    },

    /* 登录点击 */
    onLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          const reqDate = {
            login_name: this.loginData.account,
            pwd: md5(this.loginData.pwd),
            ...this.geetParam,
          };
          this.loginLoading = true;
          // universalAccountV2Login(reqDate).then(rs => {
          universalAccountV2LoginGeet(reqDate).then(rs => {
            this.loginLoading = false;
            if (rs.status == 200) {
              this.handLoginSuccess(rs.data, rs.status, rs.msg);
              this.sucessData = rs.data;
              localStorage.setItem("userData", JSON.stringify(rs.data));
              this.loginStatus = rs.status;
              this.loginMsg = rs.msg;
            }
          });
        }
      });
    },
    /* 登录成功|失败处理 */
    handLoginSuccess(data, status, msg) {
      let { authInfo = {}, accountInfo, tokenInfo, auth } = data;
      if (status !== 200) {
        showMessage({ message: msg ? msg : status });
        return;
      }
      const {
        is_bind_security,
        is_check,
        open_google,
        open_email,
        open_mobile,
      } = authInfo;
      // 未绑定二次验证进首次绑定页面
      if (is_bind_security == 1) {
        // 写入cookie，存储 token
        Cookies.set("topcredit_exchange_t", tokenInfo.token);
        // Cookies.set("_token", tokenInfo.token || null, "/hxexchge.com");
        Cookies.set("_token", tokenInfo.token || null, {
          expires: 1,
          path: "",
          domain: ".hxexchge.com",
        });

        sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);
        let jump = this.$route.fullPath.split("redirect=")[1] || "";
        if (jump) {
          jump = decodeURIComponent(jump);
        }
        // 跳到首次登录绑定
        // this.$router.push({
        //   path: "login/verify",
        //   query: { accountInfo, jump },
        // });
        this.$store.dispatch("user/getUserInfo").then(res => {
          this.successLogin(auth);
        });
        return;
      }

      // 写入cookie，存储 token
      sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);
      // 跳转二次认证页面
      let jump = this.$route.fullPath.split("redirect=")[1] || "";
      if (jump) {
        jump = decodeURIComponent(jump);
      }
      this.$router.push({
        name: "LoginMobile",
        params: { authInfo, accountInfo, jump },
      });
    },

    successLogin() {
      this.$router.push({ path: "/" });
      return true;
    },
  },
};
</script>
<style lang="scss">
.login_container {
  width: 100%;
  height: calc(100vh - 60px);
  background-image: linear-gradient(174deg, #19273c 12%, #273244 92%);
  background: url("../../assets/svg/login-bg.png");
  background-color: #000;
  background-size: 100% 100%;
  min-height: 600px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  .bottom {
    margin: 20px auto;
    width: 500px;
    height: 110px;
  }
  .banner-content {
    width: 500px;
    height: 110px;
    border-radius: 4px;
    // background-color: #0f1828;
    background-image: linear-gradient(68deg, #c61b3d 21%, #ff486d 82%);
    display: flex;
    justify-content: space-between;
    .left-banner {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 25px;
      img {
        width: 64px;
        height: 64px;
        background: #ffffff;
      }
      .qr_code_canvas {
        width: 63px;
        height: 63px;
        background: #ffffff;
        padding: 3px;
        canvas {
          margin: -3px 0 0 -3px;
        }
      }
      .left-text {
        height: 64px;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .banner-top {
          .banner-title {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }
          .banner-sub-title {
            height: 16px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .banner-bottom {
          color: #fff;
          opacity: 0.5;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .right-banner {
      padding-right: 10px;
      padding-top: 10px;
      img {
        width: 138px;
        height: 100px;
      }
    }
  }

  /* 登录 */
  .login {
    width: 500px;
    min-height: 316px;
    margin: 0px auto 0;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    position: relative;
    z-index: 5;
    overflow: hidden;

    h3 {
      text-align: center;
      color: #354052;
      font-size: 20px;
      font-weight: 500;
      padding: 23px 31px 29px;
      padding-bottom: 0;
    }

    .tab-change {
      height: 54px;
      padding: 10px 80px 0;
      .tabs {
        .el-tabs__nav-wrap {
          &:after {
            background-color: transparent;
          }
          /* 选项 */
          .el-tabs__item {
            color: #333;
            font-size: 16px;
            font-weight: normal;
          }
          /* 底部条 */
          .el-tabs__active-bar {
            background: #ffffff;
            content: "";

            &:after {
              content: "";
              height: 3px;
              width: 20px;
              background-color: #c61b3d;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    /* 表单 */
    .login-form {
      padding: 0 50px;
      margin-top: 20px;
      .pwd {
        margin-bottom: 28px;
      }
      .el-form {
        .station-select {
          .el-select {
            width: 100%;
          }
        }
        input {
          height: 54px;
          border-radius: 2px;
          &:focus {
            border-color: #c61b3d;
          }
        }

        /* 每一项 */
        .el-form-item {
          padding-top: 3px;
        }

        /* 区号-手机号 */
        .area-mobile {
          margin-top: 0px;
          display: flex;
          .el-form-item {
            .el-select .el-input.is-focus .el-input__inner {
              border-color: #c61b3d;
            }
            &:first-child {
              width: 89.7px;
              margin-right: 6px;
              input {
                padding-right: 20px;
              }
            }
            &:last-child {
              flex: 1;
            }
          }
        }

        /* 邮箱 */
        .account {
          margin-top: 0px;
        }

        /* 登录 */
        .login-btn {
          width: 100%;
          height: 48px;
          color: #fff;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          border-radius: 2px;
          background-image: linear-gradient(264deg, #ff486d 10%, #c61b3d 40%);
          padding: 0;
          border: 0px;
          cursor: pointer;
          &:hover {
            border: 0px;
            background-image: linear-gradient(264deg, #ff486d 10%, #c61b3d 40%);
          }
        }
      }
    }
    /* 去注册-忘记密码 */
    .register-forget {
      color: #333;
      font-size: 14px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      padding: 10px 50px;
      padding-bottom: 30px;
      .go-resiger {
        margin-left: -4px;
      }
      a {
        color: #3348f6;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .el-input__suffix-inner {
    img {
      width: 18px;
      height: 16px;
      padding: 1px 0;
      color: #e2e2e4;
      cursor: pointer;
      line-height: 48px;
      margin-top: 20px;
      margin-right: 15px;
      // padding-right: 15px;
    }
    .open {
      margin-top: 22px;
      width: 18px;
      height: 12px;
      color: #c2c7d0;
    }
  }

  /* 版权 */
  .copyright {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
}
</style>
